import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { useGetRecipientById } from 'src/client/hooks/queries';
import routes from 'src/commons/constants/routes';
import { SPLIT_TYPE, Give } from 'src/commons/types';

import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

import LoadingAnimation from '../../LoadingAnimation';
import { Flex } from '../../StyledCommon';
import Text from '../../Text';

import Tag from '../TableTag';

type Props = {
  give: Give;
};

export function RecipientTableCell(props: Props) {
  const { give } = props;

  const adminDonorDashboardMatch = useRouteMatch({
    path: routes.ADMIN_DONOR_DASHBOARD,
    exact: true,
    strict: false,
  });
  const personalDashboardMatch = useRouteMatch({
    path: routes.DASHBOARD,
    exact: true,
    strict: true,
  });
  const personalDashboardViewMatch = useRouteMatch({
    path: routes.DONOR_PERSONAL_DASHBOARD_VIEW,
    exact: false,
    strict: false,
  });
  const adminDonorDashboardViewMatch = useRouteMatch({
    path: routes.ADMIN_DONOR_DASHBOARD_VIEW,
    exact: false,
    strict: false,
  });
  const { data: recipient, isLoading: isRecipientLoading } =
    useGetRecipientById(give.recipientId as string);
  const { data: parentRecipient, isLoading: isParentRecipientLoading } =
    useGetRecipientById(recipient?.parentRecipientId ?? undefined);

  const isInDonorDashboardPage =
    !!adminDonorDashboardMatch ||
    !!personalDashboardMatch ||
    !!personalDashboardViewMatch ||
    !!adminDonorDashboardViewMatch;

  function formatSplitTypeText(splitType: SPLIT_TYPE) {
    switch (splitType) {
      case SPLIT_TYPE.TIP:
        return 'Tip';
      case SPLIT_TYPE.PROCESSING_FEE:
        return 'Fee';
      default:
        return null;
    }
  }

  function renderHeadingName() {
    const isHighlightedNameLoading =
      isRecipientLoading || isParentRecipientLoading;

    if (isHighlightedNameLoading) {
      return <LoadingAnimation />;
    }

    if (isInDonorDashboardPage) {
      return recipient?.name;
    }

    if (parentRecipient) {
      return parentRecipient.name;
    } else {
      return recipient?.name;
    }
  }

  function renderSubheadingName() {
    if (isInDonorDashboardPage && parentRecipient) {
      return (
        <Subtitle data-cy="secondary-recipient-name">
          ({parentRecipient?.name})
        </Subtitle>
      );
    }

    if (!isInDonorDashboardPage && parentRecipient) {
      return (
        <Subtitle data-cy="secondary-recipient-name">
          ({recipient?.name})
        </Subtitle>
      );
    }

    return null;
  }

  const amountMatchedTag = give.matchedDonationAmount ? (
    <Tag icon="heart">
      + {formatToCurrency(give.matchedDonationAmount)} Matched
    </Tag>
  ) : null;

  const feeTag =
    give.splitAmount && give.splitType ? (
      <Tag icon="hand">
        + {formatToCurrency(give.splitAmount)}{' '}
        {formatSplitTypeText(give.splitType)}
      </Tag>
    ) : null;

  const frequencyTag =
    give.isRecurring && give.frequency ? (
      <Tag icon="frequency">{give.frequency}</Tag>
    ) : null;

  const noteText = give.detailEntry?.trim() ? (
    <Subtitle type="body1reg2">{give.detailEntry}</Subtitle>
  ) : null;

  return (
    <div>
      <RecipientNameText data-cy="primary-recipient-name" type="body1med2">
        {renderHeadingName()}
      </RecipientNameText>
      {renderSubheadingName()}
      {noteText}
      <Flex gap="10px">
        {amountMatchedTag}
        {feeTag}
        {frequencyTag}
      </Flex>
    </div>
  );
}

const RecipientNameText = styled(Text)`
  color: ${(props) => props.theme.colors.darkPrimary100};
`;

const Subtitle = styled(Text)`
  margin-top: 10px;
  color: ${(props) => props.theme.colors.lightEmphasis2};
`;

export default RecipientTableCell;
