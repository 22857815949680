import { arrayMove } from '@dnd-kit/sortable';

import { PageRecipient, PageRecipientVisibility } from 'src/commons/types';
import { sortObjectsByAscending } from 'src/commons/utils/ArrayUtils';

type MovePageRecipientOrderParams = {
  pageRecipients: PageRecipient[];
  pageRecipienToMoveId: string;
  newOrderIndex: number;
  newVisibility: PageRecipientVisibility;
};

type SortedAndGroupedPageRecipientsReturn<K> = {
  sortedFeaturedPageRecipients: K;
  sortedUnfeaturedPageRecipients: K;
  sortedHiddenPageRecipients: K;
};

export function sortPageRecipientsAscending(
  pageRecipientA: PageRecipient,
  pageRecipientB: PageRecipient
) {
  return (pageRecipientA.order as number) - (pageRecipientB.order as number);
}

export function movePageRecipientOrder(params: MovePageRecipientOrderParams) {
  const { pageRecipients, pageRecipienToMoveId, newOrderIndex, newVisibility } =
    params;

  const sortedAndGroupedPageRecipients =
    sortAndGroupPageRecipients(pageRecipients);
  const {
    sortedFeaturedPageRecipients: featuredPageRecipients,
    sortedUnfeaturedPageRecipients: unfeaturedPageRecipients,
    sortedHiddenPageRecipients: hiddenPageRecipients,
  } = sortedAndGroupedPageRecipients;
  const newPageRecipientsOrder = {
    [PageRecipientVisibility.FEATURED]: featuredPageRecipients,
    [PageRecipientVisibility.UNFEATURED]: unfeaturedPageRecipients,
    [PageRecipientVisibility.HIDDEN]: hiddenPageRecipients,
  };

  const pageRecipientToMove = pageRecipients.find(
    (pageRecipient) => pageRecipient.id === pageRecipienToMoveId
  ) as PageRecipient;

  const isMovedToDifferentVisibility =
    newVisibility !== pageRecipientToMove.visibility;

  if (isMovedToDifferentVisibility) {
    const pageRecipientGroupToMoveFrom = getPageRecipientGroup(
      pageRecipientToMove.visibility,
      sortedAndGroupedPageRecipients
    );
    const pageRecipientGroupToMoveTo = getPageRecipientGroup(
      newVisibility,
      sortedAndGroupedPageRecipients
    );

    const pageRecipientGroupToMoveFromNewIndexOrder =
      pageRecipientGroupToMoveFrom.filter(
        (pageRecipient) => pageRecipient.id !== pageRecipientToMove.id
      );

    const pageRecipientGroupToMoveToNewIndexOrder: PageRecipient[] = [
      ...pageRecipientGroupToMoveTo.slice(0, newOrderIndex),
      { ...pageRecipientToMove, visibility: newVisibility },
      ...pageRecipientGroupToMoveTo.slice(newOrderIndex),
    ];

    const pageRecipientGroupToMoveFromWithUpdatedOrderProp =
      updatePageRecipientOrderPropBasedOnIndexPosition(
        pageRecipientGroupToMoveFromNewIndexOrder
      );

    const pageRecipientGroupToMoveToWithUpdatedOrderProp =
      updatePageRecipientOrderPropBasedOnIndexPosition(
        pageRecipientGroupToMoveToNewIndexOrder
      );

    newPageRecipientsOrder[pageRecipientToMove.visibility] =
      pageRecipientGroupToMoveFromWithUpdatedOrderProp;
    newPageRecipientsOrder[newVisibility] =
      pageRecipientGroupToMoveToWithUpdatedOrderProp;
  } else {
    const pageRecipientGroup = getPageRecipientGroup(
      pageRecipientToMove.visibility,
      sortedAndGroupedPageRecipients
    );
    const pageRecipientGroupNewIndexOrder = arrayMove(
      pageRecipientGroup,
      pageRecipientGroup.indexOf(pageRecipientToMove),
      newOrderIndex
    );

    const pageRecipientGroupWithUpdatedOrderProp =
      updatePageRecipientOrderPropBasedOnIndexPosition(
        pageRecipientGroupNewIndexOrder
      );

    newPageRecipientsOrder[pageRecipientToMove.visibility] =
      pageRecipientGroupWithUpdatedOrderProp;
  }

  return Object.values(newPageRecipientsOrder).flat();
}

function getPageRecipientGroup(
  visibility: PageRecipientVisibility,
  groupedPageRecipients: SortedAndGroupedPageRecipientsReturn<PageRecipient[]>
) {
  switch (visibility) {
    case PageRecipientVisibility.FEATURED:
      return groupedPageRecipients.sortedFeaturedPageRecipients;
    case PageRecipientVisibility.UNFEATURED:
      return groupedPageRecipients.sortedUnfeaturedPageRecipients;
    case PageRecipientVisibility.HIDDEN:
      return groupedPageRecipients.sortedHiddenPageRecipients;
  }
}

function updatePageRecipientOrderPropBasedOnIndexPosition(
  pageRecipients: PageRecipient[]
) {
  return pageRecipients.map((pageRecipient, index) => ({
    ...pageRecipient,
    order: index,
  }));
}

export function sortAndGroupPageRecipients<
  T extends PageRecipient[] | undefined
>(pageRecipients: T): SortedAndGroupedPageRecipientsReturn<T> {
  const featuredPageRecipients = pageRecipients?.filter(
    (pageRecipient) =>
      pageRecipient.visibility === PageRecipientVisibility.FEATURED
  );

  const sortedFeaturedPageRecipients =
    featuredPageRecipients &&
    sortObjectsByAscending(featuredPageRecipients, 'order');

  const unfeaturedPageRecipients = pageRecipients?.filter(
    (pageRecipient) =>
      pageRecipient.visibility === PageRecipientVisibility.UNFEATURED
  );

  const sortedUnfeaturedPageRecipients =
    unfeaturedPageRecipients &&
    sortObjectsByAscending(unfeaturedPageRecipients, 'order');

  const hiddenPageRecipients = pageRecipients?.filter(
    (pageRecipient) =>
      pageRecipient.visibility === PageRecipientVisibility.HIDDEN
  );

  const sortedHiddenPageRecipients =
    hiddenPageRecipients &&
    sortObjectsByAscending(hiddenPageRecipients, 'order');

  return {
    sortedFeaturedPageRecipients: sortedFeaturedPageRecipients as T,
    sortedUnfeaturedPageRecipients: sortedUnfeaturedPageRecipients as T,
    sortedHiddenPageRecipients: sortedHiddenPageRecipients as T,
  };
}
