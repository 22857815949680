import React from 'react';

import { Button, Text } from 'src/client/components';
import { STATUS_OPTIONS, SectionStatus } from 'src/client/types/Gallery';
import { pluralize } from 'src/commons/utils/StringUtils';

import RightButtonsContainer from './components/RightButtonsContainer';
import * as S from './styles';

type Props = {
  isSpacer: boolean;
  onCloseAllEdit: () => void;
  onDiscardAllChanges: () => void;
  onSaveAllChanges: () => void;
  sectionsStatus: SectionStatus[];
  isSaving: boolean;
};

function NavbarEditMode(props: Props) {
  const {
    isSpacer,
    onCloseAllEdit,
    onDiscardAllChanges,
    onSaveAllChanges,
    sectionsStatus,
    isSaving,
  } = props;

  const status: STATUS_OPTIONS = getStatus();
  const sectionsWithChanges = sectionsStatus.filter(
    (sectionStatus) =>
      sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_CHANGES
  );

  function getStatus() {
    const isOneOfSectionsHasChanges = sectionsStatus.some(
      (sectionStatus) =>
        sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_CHANGES
    );
    const isOneOfSectionsEditingWithNoChanges = sectionsStatus.some(
      (sectionStatus) =>
        sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_NO_CHANGES
    );

    if (isOneOfSectionsHasChanges) {
      return STATUS_OPTIONS.EDITING_WITH_CHANGES;
    } else if (isOneOfSectionsEditingWithNoChanges) {
      return STATUS_OPTIONS.EDITING_WITH_NO_CHANGES;
    } else {
      return STATUS_OPTIONS.VIEW_MODE;
    }
  }

  function renderActionButtons() {
    switch (status) {
      case STATUS_OPTIONS.EDITING_WITH_NO_CHANGES:
        return (
          <S.CloseEditButton
            data-cy={isSpacer ? undefined : 'close-edits-btn'}
            type="elevated3"
            onClick={onCloseAllEdit}
          >
            Close Edits
          </S.CloseEditButton>
        );
      case STATUS_OPTIONS.EDITING_WITH_CHANGES:
        return (
          <RightButtonsContainer>
            <S.UnsavedSectionText
              data-cy={isSpacer ? undefined : 'unsaved-section-count-text'}
              type="body1reg2"
            >
              {sectionsWithChangesCount} unsaved{' '}
              {pluralize('section', sectionsWithChangesCount)}
            </S.UnsavedSectionText>
            <S.DiscardButton
              data-cy={isSpacer ? undefined : 'discard-all-changes-btn'}
              onClick={onDiscardAllChanges}
            >
              Discard all changes
            </S.DiscardButton>
            <Button
              data-cy={isSpacer ? undefined : 'save-all-changes-btn'}
              loading={isSaving}
              type="elevated2"
              onClick={onSaveAllChanges}
            >
              Save all changes
            </Button>
          </RightButtonsContainer>
        );
      default:
        return null;
    }
  }

  const sectionsWithChangesCount = sectionsWithChanges.length;

  return (
    <S.Container
      animate={{
        y: 0,
      }}
      data-cy={isSpacer ? undefined : 'navbar-edit-mode'}
      exit={{
        y: -100,
      }}
      initial={{ y: -100 }}
      isSpacer={isSpacer}
    >
      <Text type="h5semibold2">Edit your gallery</Text>
      {renderActionButtons()}
    </S.Container>
  );
}

export default NavbarEditMode;
