import React, { useState } from 'react';

import { TextType } from 'src/client/types/Text';
import { PageRecipient } from 'src/commons/types';

import * as S from './styles';

type Props = {
  pageRecipient: PageRecipient;
  pageRecipientLogoReplacementTextType: TextType;
};

function RecipientLogo(props: Props) {
  const { pageRecipient, pageRecipientLogoReplacementTextType } = props;

  const [isLogoBroken, setIsLogoBroken] = useState(false);

  function handleImageError() {
    setIsLogoBroken(true);
  }

  return isLogoBroken ? (
    <S.RecipientLogoReplacementContainer>
      <S.RecipientLogoReplacement type={pageRecipientLogoReplacementTextType}>
        {pageRecipient.recipientName}
      </S.RecipientLogoReplacement>
    </S.RecipientLogoReplacementContainer>
  ) : (
    <S.RecipientLogo
      src={pageRecipient.imagePath ?? ''}
      onError={handleImageError}
    />
  );
}

export default RecipientLogo;
