import { IMAGE_OWNER_TYPE } from 'src/commons/types';

import { convertObjectToFormData } from '../utils/FormDataUtils';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'upload';
const url = `${apiVersion}/${endpoint}`;

type UploadFileParams = {
  file: File;
  model: string;
  modelId: string;
  mountedAs: string;
};

type UploadPlatformImageParams = {
  file: File;
  platformId: string;
};
type UploadRecipientImageParams = {
  file: File;
  recipientId: string;
  isPrimaryLogo: boolean;
};

type UploadPageFooterImageParams = {
  file: File;
  pageId: string;
};

const contentTypeFormData = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export async function uploadFile(params: UploadFileParams) {
  const formData = convertObjectToFormData(params);

  const { data } = await ApiClient.post(
    `${url}`,
    formData,
    contentTypeFormData
  );

  return data;
}

export async function uploadPlatformImage(params: UploadPlatformImageParams) {
  const formData = convertObjectToFormData(params);

  const { data } = await ApiClient.post(
    `${url}/${IMAGE_OWNER_TYPE.PLATFORM}`,
    formData,
    contentTypeFormData
  );

  return data;
}

export async function uploadRecipientImage(params: UploadRecipientImageParams) {
  const formData = convertObjectToFormData(params);

  const { data } = await ApiClient.post(
    `${url}/${IMAGE_OWNER_TYPE.RECIPIENT}`,
    formData,
    contentTypeFormData
  );

  return data;
}

export async function uploadPageFooterImage(
  params: UploadPageFooterImageParams
) {
  const formData = convertObjectToFormData(params);

  const { data } = await ApiClient.post(
    `${url}/page/footer`,
    formData,
    contentTypeFormData
  );

  return data;
}
