import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef, useState } from 'react';

import { DEFAULT_GALLERY_TITLE } from 'src/commons/constants/gallery';
import { getLastLetter } from 'src/commons/utils/StringUtils';

import * as S from './styles';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

const EditableGalleryTitleInput = (props: Props) => {
  const { onChange, value } = props;

  const [endText, setEndText] = useState<string>(DEFAULT_GALLERY_TITLE);
  const [inputWidthReferenceValue, setInputWidthReferenceValue] = useState<
    undefined | string
  >(value);

  const inputRef = useRef<HTMLInputElement>(null);
  const inputWidthReference = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setInputWidthReferenceValue(value);

    if (isEmpty(value)) {
      setInputWidthReferenceValue('Name');
    }
  }, [value]);

  useEffect(() => {
    setInputWidthToInputWidthReference();
  }, [inputWidthReferenceValue]);

  function setInputWidthToInputWidthReference() {
    if (inputWidthReference.current && inputRef.current) {
      inputRef.current.style.width = `${inputWidthReference.current.offsetWidth}px`;
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value;

    if (getLastLetter(inputValue) === 's') {
      setEndText('‘ Giving Side');
    } else {
      setEndText(DEFAULT_GALLERY_TITLE);
    }

    if (onChange) {
      onChange(inputValue);
    }
  }

  function handleContainerClick() {
    inputRef.current?.focus();
  }

  return (
    <S.Container data-cy="intro-title-edit-box" onClick={handleContainerClick}>
      <input
        data-cy="owner-name-input"
        placeholder="Name"
        ref={inputRef}
        value={value}
        onChange={handleChange}
      />
      <S.InputWidthReference
        data-cy="gallery-title-input-width-reference"
        ref={inputWidthReference}
      >
        {inputWidthReferenceValue}
      </S.InputWidthReference>
      <S.EndText data-cy="intro-title-edit-end-text">{endText}</S.EndText>
    </S.Container>
  );
};

export default EditableGalleryTitleInput;
