import {
  DndContext,
  DragEndEvent,
  SensorDescriptor,
  SensorOptions,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { forwardRef } from 'react';

import { LoadingCard } from 'src/client/components';
import { PageRecipient } from 'src/commons/types';

import GiveCard from '../GiveCard';

import * as S from './styles';

type Props = {
  featuredPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
  onHidePageRecipient: (pageRecipientId: string) => void;
  onUnfeaturePageRecipient: (pageRecipientId: string) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
};

const FeaturedCardsEditMode = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      featuredPageRecipients,
      isLoading,
      onHidePageRecipient,
      onUnfeaturePageRecipient,
      sensors,
      handleDragEnd,
      handleDragStart,
    } = props;

    const screens = useBreakpoint();

    const featuredPageRecipientIds = featuredPageRecipients?.map(
      (pageRecipient) => pageRecipient.id
    );

    const featuredCards = featuredPageRecipients?.map((pageRecipient) => (
      <GiveCard
        isEditMode
        data-page-recipient-visibility="featured-card"
        editModeConfig={{
          primaryButtonText: 'Unfeature',
          primaryButtonProps: {
            onClick: () => onUnfeaturePageRecipient(pageRecipient.id),
          },
          secondaryButtonProps: {
            onClick: () => onHidePageRecipient(pageRecipient.id),
          },
        }}
        key={pageRecipient.id}
        pageRecipient={pageRecipient}
      />
    ));

    return (
      <DndContext
        autoScroll={{
          acceleration: 500,
        }}
        modifiers={[restrictToParentElement]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <S.StyledDragAndDropArea
          data-cy="featured-cards-drag-and-drop-area"
          emptyStateInstructionText={
            <div>
              To feature a recipient, {screens.lg ? 'hover' : 'tap'} over them
              and click <span>Feature</span>
            </div>
          }
          instructionTabText="Drag and drop recipients to rearrange their order"
          isEmpty={!featuredPageRecipients?.length}
          ref={ref}
        >
          <S.SpotlightText type="body1reg2">
            Spotlight up to 3 organizations in this section
          </S.SpotlightText>
          <LoadingCard
            isLoading={isLoading}
            spinProps={{
              'data-cy': 'featured-cards-spinner',
            }}
          >
            <SortableContext items={featuredPageRecipientIds as string[]}>
              <S.FeaturedCardsContainer>
                {featuredCards}
              </S.FeaturedCardsContainer>
            </SortableContext>
          </LoadingCard>
        </S.StyledDragAndDropArea>
      </DndContext>
    );
  }
);

export default FeaturedCardsEditMode;
