import { STATUS_OPTIONS, SectionStatus } from 'src/client/types/Gallery';

export function isSectionEditing(sectionStatus: SectionStatus) {
  return (
    sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_NO_CHANGES ||
    sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_CHANGES
  );
}

export function isSectionSaveButtonDisabled(sectionStatus: SectionStatus) {
  return sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_NO_CHANGES;
}

export function isSectionHasChanges(sectionStatus: SectionStatus) {
  return sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_CHANGES;
}
