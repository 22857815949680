import { Form, FormInstance } from 'antd';
import React from 'react';

import { getLastLetter } from 'src/commons/utils/StringUtils';

import EditableGalleryTitleInput from '../EditableGalleryTitleInput';

import * as S from './styles';

type Props = {
  isEditing: boolean;
};

function GalleryTitle(props: Props) {
  const { isEditing } = props;

  function makeGalleryTitle(ownerName: string) {
    const ownerNameLastLetter = getLastLetter(ownerName);

    if (ownerNameLastLetter === 's') {
      return `${ownerName}‘ Giving Side`;
    } else {
      return `${ownerName}‘s Giving Side`;
    }
  }

  function renderGalleryTitle(formInstance: FormInstance<any>) {
    const { getFieldValue } = formInstance;

    const isIntroductionTitleEditable = getFieldValue(
      'isIntroductionTitleEditable'
    );
    const ownerName = getFieldValue('ownerName');

    if (isEditing && isIntroductionTitleEditable) {
      return (
        <>
          <S.StyledEditLabel>Gallery title - Your name</S.StyledEditLabel>
          <Form.Item
            data-cy="owner-name-form-item"
            name="ownerName"
            rules={[
              {
                required: true,
                message: 'Add a title for your gallery',
              },
            ]}
          >
            <EditableGalleryTitleInput />
          </Form.Item>
        </>
      );
    }

    const introductionTitleText = isIntroductionTitleEditable
      ? makeGalleryTitle(ownerName)
      : 'My Giving Side';

    return (
      <S.DescriptionTitle data-cy="intro-title-text">
        {introductionTitleText}
      </S.DescriptionTitle>
    );
  }

  return (
    <S.Container>
      <Form.Item noStyle dependencies={['isIntroductionTitleEditable']}>
        {renderGalleryTitle}
      </Form.Item>
    </S.Container>
  );
}

export default GalleryTitle;
