import { Col, Form, FormInstance, Row } from 'antd';
import React, { useContext, useState } from 'react';

import { AccountContext } from 'src/client/contexts/AccountContext';
import { useSectionStatus } from 'src/client/hooks/useSectionStatus';
import { analytics } from 'src/client/libs/segment';
import { STATUS_OPTIONS, SectionStatus } from 'src/client/types/Gallery';
import {
  DEFAULT_GALLERY_DESCRIPTION,
  DEFAULT_GALLERY_SHAPE,
} from 'src/commons/constants/gallery';
import { EMAIL_QUOTES } from 'src/commons/constants/quotes';
import { SEGMENT_EVENTS } from 'src/commons/constants/segment';
import { Page } from 'src/commons/types';

import AllowToEditGalleryTitleCheckbox from '../AllowGalleryTitleCustomizationCheckbox';
import ArrowGuide1 from '../ArrowGuide1';
import GalleryTitle from '../GalleryTitle';

import GalleryDescriptionTextArea from './components/GalleryDescriptionTextArea';
import GalleryShape from './components/GalleryShape';
import QuoteTextArea from './components/QuoteTextArea';
import QuotesDrawer, { QuoteObject } from './components/QuotesDrawer';

import * as S from './styles';

const SET_QUOTE_DELAY = 500;

type Props = {
  introSectionForm: FormInstance<any>;
  isSaving: boolean;
  isViewingPublicly: boolean;
  onSave: () => void;
  page: Page;
  sectionStatus: SectionStatus;
  setSectionStatus: (newStatus: STATUS_OPTIONS) => void;
};

function IntroSection(props: Props) {
  const {
    page,
    sectionStatus,
    isViewingPublicly,
    setSectionStatus,
    introSectionForm,
    onSave,
    isSaving,
  } = props;

  const account = useContext(AccountContext);
  const { isEditing, isSaveButtonDisabled } = useSectionStatus(sectionStatus);

  const [open, setOpen] = useState(false);
  const [isSetQuoteLoading, setIsSetQuoteLoading] = useState(false);

  function startEditing() {
    setSectionStatus(STATUS_OPTIONS.EDITING_WITH_NO_CHANGES);
    analytics.track(
      SEGMENT_EVENTS.USER_CLICKED_AN_EDIT_GALLERY_SECTION_BUTTON,
      {
        page,
        ownerName: page.ownerName,
        section: 'introduction',
      }
    );
  }

  function cancelEditing() {
    introSectionForm.resetFields();
    setSectionStatus(STATUS_OPTIONS.VIEW_MODE);
  }

  function handleSaveSection() {
    onSave();

    analytics.track(SEGMENT_EVENTS.USER_SAVED_A_GALLERY_SECTION, {
      section: 'introduction',
      introSectionFormValues: introSectionForm.getFieldsValue(),
    });
  }

  function handleFormChange() {
    const isFieldsTouched = introSectionForm.isFieldsTouched();

    if (isFieldsTouched) {
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_CHANGES);
    } else {
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_NO_CHANGES);
    }
  }

  function handleSeeAllQuotes() {
    setOpen((prev) => !prev);
  }

  function handleSelectQuote(quote: QuoteObject) {
    const currentValues = introSectionForm.getFieldsValue();

    if (
      quote.quote === currentValues.quote &&
      quote.author === currentValues.author
    ) {
      return;
    }

    setIsSetQuoteLoading(true);

    setTimeout(() => {
      setIsSetQuoteLoading(false);
    }, SET_QUOTE_DELAY);

    introSectionForm.setFieldsValue({ ...quote });
    handleFormChange();
  }

  function handleRandomizeQuote() {
    const randomIndex = secureRandomNumber(EMAIL_QUOTES.length);
    const randomQuote = EMAIL_QUOTES[randomIndex];

    handleSelectQuote({
      author: randomQuote.author,
      quote: randomQuote.message,
    });
  }

  function secureRandomNumber(maxValue: number) {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);

    return array[0] % maxValue;
  }

  function populateDefaultGalleryDescription() {
    const defaultYear = new Date(
      account?.dateCreated as unknown as string
    ).getFullYear();
    const defaultString = `${DEFAULT_GALLERY_DESCRIPTION}\nEst. ${defaultYear}`;

    introSectionForm.setFieldsValue({
      introductionDescription: defaultString,
    });
    handleFormChange();
  }

  function clearGalleryDescription() {
    introSectionForm.setFieldsValue({ introductionDescription: '' });
    handleFormChange();
  }

  const isQuoteEmptyAndViewingPublicly = !page.quote && isViewingPublicly;

  const allowGalleryTitleCustomizationCheckbox = isEditing && (
    <AllowToEditGalleryTitleCheckbox />
  );
  const isQuoteTextAreaRendering = isQuoteEmptyAndViewingPublicly;

  return (
    <>
      <S.Container>
        <Row justify="center">
          <Col md={21} xs={22}>
            <S.Logo src="/logo.png" />
            <S.StyledEditControlButtons
              cancelButtonCyId="intro-cancel-btn"
              editButtonCyId="intro-edit-btn"
              editButtonText={{
                bigScreenText: 'Edit Intro',
                smallScreenText: 'Edit',
              }}
              isEditing={isEditing}
              isSaveButtonDisabled={isSaveButtonDisabled}
              isSaving={isSaving}
              isViewingPublicly={isViewingPublicly}
              saveButtonCyId="intro-save-btn"
              title="Intro Section"
              onCancel={cancelEditing}
              onEdit={startEditing}
              onSave={handleSaveSection}
            />
            <Form
              form={introSectionForm}
              initialValues={{
                quote: page.quote,
                author: page.author,
                introductionDescription: page.introductionDescription,
                isIntroductionTitleEditable:
                  page.isIntroductionTitleEditable ?? true,
                ownerName: page.ownerName,
                galleryShape: {
                  color:
                    page.galleryShape?.color ?? DEFAULT_GALLERY_SHAPE?.color,
                  name: page.galleryShape?.name ?? DEFAULT_GALLERY_SHAPE?.name,
                },
              }}
              onFieldsChange={handleFormChange}
            >
              <QuoteTextArea
                handleRandomizeQuote={handleRandomizeQuote}
                handleSeeAllQuotes={handleSeeAllQuotes}
                isEditing={isEditing}
                isSetQuoteLoading={isSetQuoteLoading}
                isViewingPublicly={isViewingPublicly}
                page={page}
              />
              <S.DescriptionContainer
                isQuoteTextAreaRendering={isQuoteTextAreaRendering}
                isViewingPublicly={isViewingPublicly}
              >
                <GalleryShape
                  introSectionForm={introSectionForm}
                  isEditing={isEditing}
                  onChange={handleFormChange}
                />
                <S.DescriptionWrapper>
                  <ArrowGuide1
                    isInsideGalleryDescription={false}
                    isQuoteTextAreaRendering={!isQuoteEmptyAndViewingPublicly}
                    isViewingPublicly={isViewingPublicly}
                  />
                  <GalleryTitle isEditing={isEditing} />
                  {allowGalleryTitleCustomizationCheckbox}
                  <GalleryDescriptionTextArea
                    isEditing={isEditing}
                    isQuoteTextAreaRendering={!isQuoteEmptyAndViewingPublicly}
                    isViewingPublicly={isViewingPublicly}
                    page={page}
                    onCollapseDescription={clearGalleryDescription}
                    onPopulateDefaultDescription={
                      populateDefaultGalleryDescription
                    }
                  />
                </S.DescriptionWrapper>
              </S.DescriptionContainer>
            </Form>
          </Col>
        </Row>
      </S.Container>
      <QuotesDrawer
        open={open}
        selectedQuote={{
          author: introSectionForm.getFieldValue('author'),
          quote: introSectionForm.getFieldValue('quote'),
        }}
        onClose={() => setOpen(false)}
        onSelectQuote={handleSelectQuote}
      />
    </>
  );
}

export default IntroSection;
