import { Page } from 'src/commons/types';

import { EMAIL_AUTHORS, EMAIL_MESSAGES } from './quotes';

export const MAX_FEATURED_CARDS_COUNT = 3;

export enum GridOptionType {
  SHAPE = 'shape',
  COLOR = 'color',
}

export enum GalleryShape {
  SHAPE_1 = 'shape1',
  SHAPE_2 = 'shape2',
  SHAPE_3 = 'shape3',
  SHAPE_4 = 'shape4',
  SHAPE_5 = 'shape5',
  SHAPE_6 = 'shape6',
  SHAPE_7 = 'shape7',
}

export enum GalleryColor {
  COLOR_1 = 'color1',
  COLOR_2 = 'color2',
  COLOR_3 = 'color3',
  COLOR_4 = 'color4',
  COLOR_5 = 'color5',
}

export const GALLERY_COLOR_VALUES = {
  [GalleryColor.COLOR_1]: '#E520C6',
  [GalleryColor.COLOR_2]: '#2FECFC',
  [GalleryColor.COLOR_3]: '#AD3CF3',
  [GalleryColor.COLOR_4]: '#FF9820',
  [GalleryColor.COLOR_5]: '#FFFFFF',
};

export const GALLERY_COLORS = [
  GalleryColor.COLOR_1,
  GalleryColor.COLOR_2,
  GalleryColor.COLOR_3,
  GalleryColor.COLOR_4,
];

export const DEFAULT_GALLERY_SHAPE: Page['galleryShape'] = {
  color: GalleryColor.COLOR_4,
  name: GalleryShape.SHAPE_7,
};

export const DEFAULT_GALLERY_DESCRIPTION =
  'A few of the people, organizations, and causes I care about.';

export const DEFAULT_GALLERY_INTRODUCTION_QUOTE = EMAIL_MESSAGES[0];
export const DEFAULT_GALLERY_INTRODUCTION_AUTHOR = EMAIL_AUTHORS[0];

export const DEFAULT_GALLERY_TITLE = '‘s Giving Side';
