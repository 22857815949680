import React, { useState } from 'react';

import { FlexCenter, LoadingCard } from 'src/client/components';
import { PageRecipient } from 'src/commons/types';

import * as S from './styles';

type Props = {
  unfeaturedPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
};

const maxPageRecipientToDisplay = 16;

function UnfeaturedCardsViewMode(props: Props) {
  const { unfeaturedPageRecipients, isLoading } = props;

  const [isAllPageRecipientsVisible, setIsAllPageRecipientsVisible] =
    useState(false);

  function showAllPageRecipients() {
    setIsAllPageRecipientsVisible(true);
  }

  function seeLessPageRecipients() {
    setIsAllPageRecipientsVisible(false);
  }

  const pageRecipientsToDisplay = isAllPageRecipientsVisible
    ? unfeaturedPageRecipients
    : unfeaturedPageRecipients?.slice(0, maxPageRecipientToDisplay);

  const unfeaturedCards = pageRecipientsToDisplay?.map((pageRecipient) => (
    <S.StyledGiveCard
      data-page-recipient-visibility="unfeatured-card"
      key={pageRecipient.id}
      pageRecipient={pageRecipient}
      onClick={
        pageRecipient.recipientWebsite
          ? () =>
              window.open(
                'https://' + pageRecipient.recipientWebsite!,
                '_blank'
              )
          : undefined
      }
    />
  ));

  const visibilityControlButton = isAllPageRecipientsVisible ? (
    <S.VisibilityControlButton
      data-cy="see-less-btn"
      onClick={seeLessPageRecipients}
    >
      See less
    </S.VisibilityControlButton>
  ) : (
    <S.VisibilityControlButton
      data-cy="load-more-btn"
      onClick={showAllPageRecipients}
    >
      Load more
    </S.VisibilityControlButton>
  );

  return (
    <>
      <S.CardsContainer>
        <LoadingCard
          isLoading={isLoading}
          spinProps={{
            'data-cy': 'unfeatured-cards-spinner',
          }}
        >
          {unfeaturedCards}
        </LoadingCard>
      </S.CardsContainer>
      <FlexCenter>{visibilityControlButton}</FlexCenter>
    </>
  );
}

export default UnfeaturedCardsViewMode;
