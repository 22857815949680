import { Form } from 'antd';
import React from 'react';

import { Text } from 'src/client/components';

import * as S from './styles';

function AllowToEditGalleryTitleCheckbox() {
  return (
    <S.Container>
      <Form.Item
        shouldUpdate
        name="isIntroductionTitleEditable"
        valuePropName="checked"
      >
        <S.StyledCheckbox data-cy="edit-gallery-title-checkbox">
          <S.CheckBoxTitleContainer>
            <Text type="label1med2">Customize gallery title</Text>
            <Text type="label1reg2">
              Add your name to welcome friends and family to your Giving Side
            </Text>
          </S.CheckBoxTitleContainer>
        </S.StyledCheckbox>
      </Form.Item>
    </S.Container>
  );
}

export default AllowToEditGalleryTitleCheckbox;
