import {
  LinkOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Popover, message } from 'antd';
import React, { useState } from 'react';

import { useQueryClient } from 'react-query';

import { useTheme } from 'styled-components';

import { Box, Button, Text } from 'src/client/components';

import { useUpdatePageMutation } from 'src/client/hooks/mutations';
import { updateGetPageQueryData } from 'src/client/hooks/queries';
import { analytics } from 'src/client/libs/segment';
import { SEGMENT_EVENTS } from 'src/commons/constants/segment';
import { Page } from 'src/commons/types';

import * as S from './styles';

type Props = {
  children: React.ReactNode;
  page: Page;
  isPrivate: boolean;
  setIsPrivate: React.Dispatch<React.SetStateAction<boolean>>;
  publicLinkDisplayText: string;
  publicLink: string;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
function PrivacyChangeConfirmPopover(props: Props) {
  const {
    children,
    page,
    isPrivate,
    setIsPrivate,
    publicLinkDisplayText,
    publicLink,
  } = props;

  const { colors } = useTheme();
  const queryClient = useQueryClient();

  const [shouldShowSuccessContent, setShouldShowSuccessContent] =
    useState(false);
  const { mutateAsync: updatePage, isLoading: isUpdatingPage } =
    useUpdatePageMutation();

  async function publishGallery() {
    await updatePage({
      id: page.id,
      isVisible: true,
    });
    updateGetPageQueryData({
      data: {
        id: page.id,
        isVisible: true,
      },
      queryClient,
    });

    setIsPrivate(false);
    setShouldShowSuccessContent(true);

    analytics.track(SEGMENT_EVENTS.USER_PUBLISHED_THEIR_GALLERY, {
      pageId: page.id,
    });
  }

  async function unpublishGallery() {
    await updatePage({
      id: page.id,
      isVisible: false,
    });
    updateGetPageQueryData({
      data: {
        id: page.id,
        isVisible: false,
      },
      queryClient,
    });

    setIsPrivate(true);
    setShouldShowSuccessContent(true);
  }

  function getTitle() {
    if (shouldShowSuccessContent && !isPrivate) {
      return 'Your gallery is live!';
    } else if (shouldShowSuccessContent && isPrivate) {
      return 'Your gallery is hidden!';
    } else if (isPrivate) {
      return 'Make Public';
    } else {
      return 'Make Private';
    }
  }

  function getDescription() {
    if (shouldShowSuccessContent && !isPrivate) {
      return 'Share your gallery. Anyone with the link can view.';
    } else if (shouldShowSuccessContent && isPrivate) {
      return "Anyone with the link won't be able to view your gallery.";
    } else if (isPrivate) {
      return 'Publish to be able to share your gallery';
    } else {
      return 'Private to hide your gallery from others';
    }
  }

  function handlePopoverOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setShouldShowSuccessContent(false);
    }
  }

  function copyPublicLink() {
    navigator.clipboard.writeText(publicLink);
    message.info('Copied link!');
  }

  function renderSuccessActionButtons() {
    if (!isPrivate) {
      return (
        <S.SuccessActionButtonsContainer>
          <S.PublicLinkDisplay>{publicLinkDisplayText}</S.PublicLinkDisplay>
          <Button
            data-cy="popover-copy-link-btn"
            type="secondary"
            onClick={copyPublicLink}
          >
            <LinkOutlined />
            Copy
          </Button>
        </S.SuccessActionButtonsContainer>
      );
    }
  }

  function renderPrivacyChangeConfirmButton() {
    if (shouldShowSuccessContent) {
      return null;
    }

    if (isPrivate) {
      return (
        <S.PrivacyConfirmButton
          data-cy="confirm-publish-btn"
          loading={isUpdatingPage}
          type="primary"
          onClick={publishGallery}
        >
          Publish
        </S.PrivacyConfirmButton>
      );
    } else {
      return (
        <S.PrivacyConfirmButton
          data-cy="confirm-unpublish-btn"
          loading={isUpdatingPage}
          type="primary"
          onClick={unpublishGallery}
        >
          Unpublish
        </S.PrivacyConfirmButton>
      );
    }
  }

  function renderBottomPrivacyChangeButton() {
    if (!shouldShowSuccessContent) {
      return null;
    }

    if (isPrivate) {
      return (
        <S.TextButton
          data-cy="popover-publish-btn"
          loading={isUpdatingPage}
          onClick={publishGallery}
        >
          <UnlockOutlined />
          Publish
        </S.TextButton>
      );
    } else {
      return (
        <S.TextButton
          data-cy="popover-unpublish-btn"
          loading={isUpdatingPage}
          onClick={unpublishGallery}
        >
          <LockOutlined />
          Unpublish
        </S.TextButton>
      );
    }
  }

  const actionButtonsContent = shouldShowSuccessContent
    ? renderSuccessActionButtons()
    : renderPrivacyChangeConfirmButton();

  return (
    <>
      <S.GlobalStyle />
      <Popover
        content={
          <S.PrivacyPopoverContent data-cy="privacy-change-confirm-popover">
            <Text type="h5med2">{getTitle()}</Text>
            <Text type="body2reg2">{getDescription()}</Text>
            <Box margin="8px 0 0 0" />
            {actionButtonsContent}
            <S.Divider />
            <S.BottomContainer
              shouldShowSuccessContent={shouldShowSuccessContent}
            >
              <S.TextButton>
                <QuestionCircleOutlined />
                Learn more
              </S.TextButton>
              {renderBottomPrivacyChangeButton()}
            </S.BottomContainer>
          </S.PrivacyPopoverContent>
        }
        overlayInnerStyle={{
          background: colors.darkSurface1,
          borderRadius: '8px',
          padding: '4px 0',
          maxWidth: '360px',
        }}
        placement="bottomRight"
        trigger="click"
        onOpenChange={handlePopoverOpenChange}
      >
        {children}
      </Popover>
    </>
  );
}

export default PrivacyChangeConfirmPopover;
