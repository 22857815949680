import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';

import { SectionStatus } from 'src/client/types/Gallery';

import { Page } from 'src/commons/types';

import NavbarEditMode from './NavBarEditMode';
import NavbarViewMode from './NavbarViewMode';

type Props = {
  isEditing: boolean;
  isSavingAll: boolean;
  sectionsStatus: SectionStatus[];
  onCloseAllEdit: () => void;
  onDiscardAllChanges: () => void;
  onSaveAllChanges: () => void;
  page: Page;
};

function Navbar(props: Props) {
  const {
    isEditing,
    isSavingAll,
    onCloseAllEdit,
    onDiscardAllChanges,
    onSaveAllChanges,
    sectionsStatus,
    page,
  } = props;

  const screens = useBreakpoint();

  return isEditing ? (
    <>
      <NavbarEditMode
        isSaving={isSavingAll}
        isSpacer={false}
        sectionsStatus={sectionsStatus}
        onCloseAllEdit={onCloseAllEdit}
        onDiscardAllChanges={onDiscardAllChanges}
        onSaveAllChanges={onSaveAllChanges}
      />
      {screens.md && (
        <NavbarEditMode
          isSaving={isSavingAll}
          isSpacer={true}
          sectionsStatus={sectionsStatus}
          onCloseAllEdit={onCloseAllEdit}
          onDiscardAllChanges={onDiscardAllChanges}
          onSaveAllChanges={onSaveAllChanges}
        />
      )}
    </>
  ) : (
    <>
      <NavbarViewMode isSpacer={false} page={page} />
      {screens.md && <NavbarViewMode isSpacer={true} page={page} />}
    </>
  );
}

export default Navbar;
