import { PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';

import { Box, Text } from 'src/client/components';
import useTheme from 'src/client/hooks/useTheme';
import { Page } from 'src/commons/types';

import ArrowGuide1 from '../../../ArrowGuide1';

import * as S from './styles';

type Props = {
  isEditing: boolean;
  isQuoteTextAreaRendering: boolean;
  isViewingPublicly: boolean;
  page: Page;
  onCollapseDescription: () => void;
  onPopulateDefaultDescription: () => void;
};

function GalleryDescriptionTextArea(props: Props) {
  const {
    isEditing,
    isQuoteTextAreaRendering,
    isViewingPublicly,
    page,
    onCollapseDescription,
    onPopulateDefaultDescription,
  } = props;

  const { colors } = useTheme();

  const shouldShowCollapseState = isEditing && !!page.introductionDescription;
  const [collapsed, setCollapsed] = useState<boolean>(shouldShowCollapseState);

  function handleCollapseDescription() {
    onCollapseDescription();
    setCollapsed((prev) => !prev);
  }

  function handleAddGalleryDescription() {
    onPopulateDefaultDescription();
    setCollapsed((prev) => !prev);
  }

  const fieldNameLabel = isEditing && (
    <S.StyledEditLabel>Gallery description</S.StyledEditLabel>
  );

  const collapseButton = isEditing && (
    <S.CollapseDescriptionButton onClick={handleCollapseDescription} />
  );

  const isDescriptionEmptyAndViewingPublicly =
    !page.introductionDescription && isViewingPublicly;

  const arrowGuide1 = isViewingPublicly && !isQuoteTextAreaRendering && (
    <ArrowGuide1
      isInsideGalleryDescription
      isDescriptionEmpty={!page.introductionDescription}
      isQuoteTextAreaRendering={isQuoteTextAreaRendering}
      isViewingPublicly={isViewingPublicly}
    />
  );

  useEffect(() => {
    setCollapsed(shouldShowCollapseState);
  }, [isEditing, shouldShowCollapseState]);

  if (isDescriptionEmptyAndViewingPublicly) {
    return <>{arrowGuide1}</>;
  }

  return (
    <Form.Item name="introductionDescription">
      {isEditing && !collapsed ? (
        <S.AddDescriptionButton
          type="secondary"
          onClick={handleAddGalleryDescription}
        >
          <>
            <PlusOutlined />
            Add gallery description
          </>
        </S.AddDescriptionButton>
      ) : (
        <>
          {fieldNameLabel}
          <S.GalleryDescriptionInputContainer>
            <Form.Item name="introductionDescription">
              <S.GalleryDescriptionTextArea
                autoSize={{ minRows: 3 }}
                data-cy="intro-description-text-area"
                isEditing={isEditing}
                placeholder="Add your gallery description..."
              />
            </Form.Item>
            {collapseButton}
            {isEditing && (
              <Box margin="8px 0">
                <Text color={colors.darkEmphasis2} type="body2reg2">
                  Example: Hey, friends! I wanted to share with you some
                  meaningful causes that hold a special place in my heart,
                  contributing to a world filled with inclusivity and
                  compassion.
                </Text>
              </Box>
            )}
          </S.GalleryDescriptionInputContainer>
          {arrowGuide1}
        </>
      )}
    </Form.Item>
  );
}

export default GalleryDescriptionTextArea;
