import styled from 'styled-components';

import { FlexCenter } from 'src/client/components/StyledCommon';

import LoadingCard from '../../LoadingCard';

type ItemImageProps = {
  backgroundUrl: string;
};

const defaultRecipientBg = '/recipientBg.png';

export const Container = styled.div`
  position: relative;
  padding: 80px 0;
  text-align: center;

  .title {
    display: none;
    margin-top: 30px;
  }
`;

export const ItemImage = styled(FlexCenter)<ItemImageProps>`
  position: relative;
  height: 95%;
  z-index: 1;
  background-image: url('${(props) => props.backgroundUrl}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
  display: inline-flex;
  width: 100%;
`;

export const ItemContainer = styled.div`
  position: relative;
  height: 230px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const ItemBackground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(${defaultRecipientBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const StyledLoadingCard = styled(LoadingCard)`
  background: url(${defaultRecipientBg});
`;
