import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  DonorRegister,
  LoginPage,
  PageNotFound,
  PrivacyPolicyPage,
  AccountCreationInstruction,
  PublicGallery,
  LandingPage,
} from 'src/client/pages';

import routes from 'src/commons/constants/routes';

import AlternateEmailVerification from '../pages/AlternateEmailVerification';
import InitialDonorPassword from '../pages/InitialDonorPassword';
import MergeAccountsSuccess from '../pages/MergeAccountsSuccess';
import RecipientSignIn from '../pages/RecipientSignIn';
import ResendInitialPasswordLink from '../pages/ResendInitialPasswordLink';
import ResendResetPasswordLink from '../pages/ResendResetPasswordLink';
import ResetPassword from '../pages/ResetPassword';
import SendResetPasswordEmail from '../pages/SendResetPasswordEmail';

export default function UnauthorizedRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={RecipientSignIn}
        path={routes.RECIPIENT_SIGN_IN}
      />
      <Route
        exact
        component={AccountCreationInstruction}
        path={routes.ACCOUNT_CREATION_INSTRUCTION}
      />
      <Route exact component={LandingPage} path={routes.ROOT} />
      <Route exact component={LoginPage} path={routes.DONOR_SIGN_IN} />
      <Route exact component={DonorRegister} path={routes.DONOR_REGISTER} />
      <Route exact component={LoginPage} path={routes.ADMIN_SIGN_IN} />
      <Route exact component={PublicGallery} path={routes.PUBLIC_GALLERY} />
      <Route exact component={ResetPassword} path={routes.RESET_PASSWORD} />
      <Route
        exact
        component={ResendResetPasswordLink}
        path={routes.RESEND_RESET_PASSWORD_LINK}
      />
      <Route
        exact
        component={SendResetPasswordEmail}
        path={routes.SEND_RESET_PASSWORD_EMAIL}
      />
      <Route exact component={PrivacyPolicyPage} path={routes.PRIVACY_POLICY} />
      <Route
        exact
        component={ResendInitialPasswordLink}
        path={routes.RESEND_INITIAL_PASSWORD_LINK}
      />
      <Route
        exact
        component={InitialDonorPassword}
        path={routes.INITIAL_DONOR_PASSWORD}
      />
      <Route
        exact
        component={AlternateEmailVerification}
        path={routes.VERIFY_ALTERNATE_EMAIL}
      />
      <Route
        exact
        component={MergeAccountsSuccess}
        path={routes.MERGE_ACCOUNTS}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
}
