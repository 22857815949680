import { Form, FormInstance } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import React, { useState } from 'react';

import { Page } from 'src/commons/types';

import GalleryShapeModal from '../GalleryShapeModal';

import * as S from './styles';

type Props = {
  isEditing: boolean;
  introSectionForm: FormInstance<any>;
  onChange?: () => void;
};

function GalleryShape(props: Props) {
  const { isEditing, introSectionForm, onChange } = props;

  const galleryShape = useWatch('galleryShape', introSectionForm);

  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleModalVisibility() {
    setIsModalOpen(!isModalOpen);
  }

  function handleSave(newGalleryShape: Page['galleryShape']) {
    introSectionForm.setFields([
      {
        name: 'galleryShape',
        value: newGalleryShape,
        touched: true,
      },
    ]);

    if (onChange) {
      onChange();
    }

    toggleModalVisibility();
  }

  const customizeButton = isEditing && (
    <S.CustomizeButton
      data-cy="customize-shape-btn"
      size="middle"
      type="secondary"
      onClick={toggleModalVisibility}
    >
      Customize
    </S.CustomizeButton>
  );

  const galleryShapeModal = isModalOpen && (
    <GalleryShapeModal
      introSectionForm={introSectionForm}
      open={isModalOpen}
      onCancel={toggleModalVisibility}
      onSave={handleSave}
    />
  );

  return (
    <S.Container>
      <Form.Item noStyle name="galleryShape">
        <S.StyledShape
          shape={{
            name: galleryShape?.name,
            color: galleryShape?.color,
          }}
        />
      </Form.Item>
      {customizeButton}
      {galleryShapeModal}
    </S.Container>
  );
}

export default GalleryShape;
