import {
  DndContext,
  DragEndEvent,
  SensorDescriptor,
  SensorOptions,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { forwardRef } from 'react';

import { LoadingCard } from 'src/client/components';
import { MAX_FEATURED_CARDS_COUNT } from 'src/commons/constants/gallery';
import { PageRecipient } from 'src/commons/types';

import DragAndDropArea from '../DragAndDropArea';

import GiveCard from '../GiveCard';

type Props = {
  unfeaturedPageRecipients: PageRecipient[] | undefined;
  featuredPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
  onFeaturePageRecipient: (pageRecipientId: string) => void;
  onHidePageRecipient: (pageRecipientId: string) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
};

const UnfeaturedCardsEditMode = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      unfeaturedPageRecipients,
      featuredPageRecipients,
      isLoading,
      onFeaturePageRecipient,
      onHidePageRecipient,
      sensors,
      handleDragEnd,
      handleDragStart,
    } = props;

    const screens = useBreakpoint();

    const unfeaturedCards = unfeaturedPageRecipients?.map((pageRecipient) => (
      <GiveCard
        isEditMode
        data-page-recipient-visibility="unfeatured-card"
        editModeConfig={{
          primaryButtonText: 'Feature',
          isPrimaryButtonHidden:
            featuredPageRecipients?.length === MAX_FEATURED_CARDS_COUNT,
          primaryButtonProps: {
            onClick: () => {
              onFeaturePageRecipient(pageRecipient.id);
            },
          },
          secondaryButtonProps: {
            onClick: () => onHidePageRecipient(pageRecipient.id),
          },
        }}
        key={pageRecipient.id}
        pageRecipient={pageRecipient}
      />
    ));

    const unfeaturedPageRecipientIds = unfeaturedPageRecipients?.map(
      (pageRecipient) => pageRecipient.id
    );

    return (
      <DndContext
        modifiers={[restrictToParentElement]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <DragAndDropArea
          data-cy="unfeatured-cards-drag-and-drop-area"
          emptyStateInstructionText={
            <div>
              To unfeature a recipient, {screens.lg ? 'hover' : 'tap'} over them
              and click <span>Unfeature</span>
            </div>
          }
          instructionTabText="Drag and drop recipients to rearrange their order"
          isEmpty={!unfeaturedPageRecipientIds?.length}
          ref={ref}
        >
          <LoadingCard isLoading={isLoading}>
            <SortableContext items={unfeaturedPageRecipientIds as string[]}>
              {unfeaturedCards}
            </SortableContext>
          </LoadingCard>
        </DragAndDropArea>
      </DndContext>
    );
  }
);

export default UnfeaturedCardsEditMode;
