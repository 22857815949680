import { Page } from 'src/commons/types/Page.type';
import { ApiResponse } from 'src/commons/types/Response.type';
import { RequireField } from 'src/commons/utils/TypescriptUtils';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'pages';
export const url = `${apiVersion}/${endpoint}`;

type GetPageParams = {
  cursor?: string;
};

export async function getPages(
  params: GetPageParams
): Promise<ApiResponse<Page[]>> {
  const { data } = await ApiClient.get<ApiResponse<Page[]>>(url, {
    params,
  });

  return data;
}

export async function getPage(pageId: string): Promise<Page> {
  const { data } = await ApiClient.get<Page>(`${url}/${pageId}`);

  return data;
}

export async function updatePage(
  params: RequireField<Partial<Page>, 'id'>
): Promise<Page> {
  const { data } = await ApiClient.put<Page>(`${url}/${params.id}`, params);

  return data;
}

export async function getDonorGalleryPage(donorId: string): Promise<Page> {
  const { data } = await ApiClient.get<Page>(`${url}/gallery`, {
    params: { donorId },
  });

  return data;
}
