import { Affix, Form, FormInstance, message } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import omit from 'lodash/omit';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';

import { Text, Title } from 'src/client/components';
import {
  useUpdateDonorMutation,
  useUpdatePageMutation,
  useUploadPageFooterImageMutation,
} from 'src/client/hooks/mutations';
import { useUpdatePageRecipientsMutation } from 'src/client/hooks/mutations/pageRecipientMutation';
import {
  setPageRecipientsQueryData,
  updateGetDonorByIdQuery,
  updateGetPageQueryData,
  useGetPage,
  useGetPageRecipients,
} from 'src/client/hooks/queries';
import { SECTION_NAMES, STATUS_OPTIONS } from 'src/client/types/Gallery';

import { getTouchedFieldsValue } from 'src/client/utils/FormUtils';
import { GalleryColor } from 'src/commons/constants/gallery';
import { GivingAdjective } from 'src/commons/constants/givingAdjectives';
import { Page, PageRecipient } from 'src/commons/types';
import { asyncForEach } from 'src/commons/utils/ArrayUtils';
import { getCurrentYear } from 'src/commons/utils/DateUtils';
import { convertEmptyStringsToNulls } from 'src/commons/utils/ObjectUtil';
import { RequireField } from 'src/commons/utils/TypescriptUtils';

import LoadingPage from '../LoadingPage';
import PageNotFound from '../PageNotFound';

import AdjectiveSection from './components/AdjectiveSection';
import CardsSection from './components/CardsSection';
import IntroSection from './components/IntroSection';
import MobileDismissableAlert from './components/IntroSection/components/MobileDismissableAlert';
import Navbar from './components/Navbar';

import PrivacyStatusInfo from './components/PrivacyStatusInfo';
import SuccessMessage from './components/SuccessMessage';
import * as S from './styles';
import { useDonorGallerySectionStatusManager } from './useDonorGallerySectionStatusManager';

type Props = {
  isViewingPublicly: boolean;
};

type UrlParams = {
  pageId: string;
};

const { useForm } = Form;

function DonorGallery(props: Props) {
  const { isViewingPublicly } = props;

  const match = useRouteMatch<UrlParams>();
  const { pageId } = match.params;
  const screens = useBreakpoint();

  const [introSectionForm] = useForm();
  const [footerSectionForm] = useForm();
  const [pageRecipientsEditData, setPageRecipientsEditData] = useState<
    PageRecipient[]
  >([]);
  const queryClient = useQueryClient();
  const { data: page, isLoading: isGetPageLoading } = useGetPage(pageId);

  const queryDataOfGetPageRecipients = {
    pageId: page?.id as string,
  };

  const {
    data: pageRecipients,
    isLoading: isPageRecipientsLoading,
    isIdle: isPageRecipientIdle,
  } = useGetPageRecipients(queryDataOfGetPageRecipients, {
    enabled: !!page?.id,
  });

  const { mutateAsync: updatePage, isLoading: isUpdatingPage } =
    useUpdatePageMutation({
      onSuccess: (updatePayload) => {
        showSuccessMessage();

        updateGetPageQueryData({
          queryClient,
          data: updatePayload,
        });
      },
    });
  const {
    mutateAsync: updatePageRecipient,
    isLoading: isUpdatingPageRecipietns,
  } = useUpdatePageRecipientsMutation({
    onSuccess: showSuccessMessage,
  });
  const {
    mutateAsync: uploadPageFooterImage,
    isLoading: isUploadingPageFooterImage,
  } = useUploadPageFooterImageMutation({
    onSuccess: () => {},
  });

  const { mutateAsync: updateDonor, isLoading: isUpdatingDonor } =
    useUpdateDonorMutation({
      onSuccess: (data) => {
        updateGetDonorByIdQuery({
          data,
          donorId: data.id,
          queryClient,
        });

        showSuccessMessage();
      },
    });

  const {
    createSectionStatusSetter,
    exitEditModeToAllSections,
    getSectionStatus,
    isOneOfSectionsEditing,
    sectionsStatus,
    setAllSectionStatusToViewMode,
    setSectionStatus,
  } = useDonorGallerySectionStatusManager();

  function showSuccessMessage() {
    message.info({
      icon: <div></div>,
      className: 'update-page-success-message',
      content: <SuccessMessage />,
    });
  }

  async function saveAllChanges() {
    try {
      const formsToSave = [introSectionForm, footerSectionForm];

      await saveCardsSectionChanges();
      await savePageFooterImageChanges();
      await saveFormChanges(formsToSave);
      exitEditModeToAllSections(formsToSave);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async function savePageFooterImageChanges() {
    const touchedFields = getTouchedFieldsValue(footerSectionForm);

    if (!touchedFields.footerImage) {
      return null;
    }

    const file = touchedFields.footerImage[0];

    if (file) {
      await uploadPageFooterImage({
        file: file.originFileObj as File,
        pageId: page?.id as string,
      });
    } else {
      await updatePage({
        id: page?.id as string,
        footerImageFilePath: null,
      });
    }
  }

  async function saveFormChanges(formSections: FormInstance<any>[]) {
    let updatePayload: RequireField<Partial<Page>, 'id'> = {
      id: (page as Page).id,
    };
    const ignoredFormItems = ['footerImage'];

    await asyncForEach(formSections, async (formSection) => {
      await formSection.validateFields();

      const touchedFields = getTouchedFieldsValue(formSection);
      const touchedFieldsWithNulls = convertEmptyStringsToNulls(touchedFields);

      // Temporary fix for handling quote and author fields when chosen from curated quotes
      const isQuoteChanged = page?.quote !== formSection.getFieldValue('quote');
      const isAuthorChanged =
        page?.author !== formSection.getFieldValue('author');
      let quoteAuthorPayload = {};

      // Temporary fix for programmatically cleared introduction description
      const newIntroDescriptionValue = formSection.getFieldValue(
        'introductionDescription'
      );
      const isIntroductionDescriptionCleared =
        page?.introductionDescription !== newIntroDescriptionValue &&
        !newIntroDescriptionValue;

      // This code checks if the 'quote' or 'author' fields have been modified.
      // If the quote has been changed and it's not in the touchedFieldsWithNulls, or if the author has been changed,
      // the quoteAuthorPayload object is updated with the new quote or author value.
      if (
        !(
          'footerDescription' in touchedFields ||
          'footerIntroduction' in touchedFields
        )
      ) {
        quoteAuthorPayload = {
          ...(isQuoteChanged && {
            quote: formSection.getFieldValue('quote') || null,
          }),
          ...(isAuthorChanged && {
            author: formSection.getFieldValue('author') || null,
          }),
        };
      }

      updatePayload = {
        ...updatePayload,
        ...omit(touchedFieldsWithNulls, ignoredFormItems),
        ...quoteAuthorPayload,
        ...(isIntroductionDescriptionCleared &&
        !(
          'footerDescription' in touchedFields ||
          'footerIntroduction' in touchedFields
        )
          ? {
              introductionDescription: null,
            }
          : {}),
      };
    });

    await updatePage(updatePayload);
    updateGetPageQueryData({
      queryClient,
      data: updatePayload,
    });
  }

  async function saveIntroSection() {
    try {
      const formsToSave = [introSectionForm];

      await saveFormChanges(formsToSave);
      exitEditModeToAllSections(formsToSave);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async function saveCardsSectionChanges() {
    if (pageRecipientsEditData.length > 0) {
      const sanitizedPageRecipientsEditData = pageRecipientsEditData.map(
        ({ recipientWebsite, ...pageRecipientData }) => pageRecipientData
      );
      await updatePageRecipient(sanitizedPageRecipientsEditData);
      setPageRecipientsQueryData(
        queryClient,
        pageRecipientsEditData,
        queryDataOfGetPageRecipients
      );
      setPageRecipientsEditData([]);
      setSectionStatus({
        name: SECTION_NAMES.CARDS_SECTION,
        status: STATUS_OPTIONS.VIEW_MODE,
      });
    }
  }

  async function saveAdjectiveSection(payload: {
    adjective?: GivingAdjective;
    previousAdjective?: GivingAdjective;
    color?: GalleryColor;
  }) {
    const { adjective, previousAdjective, color } = payload;

    await updateDonor({
      ...(adjective && { descriptionCurrent: adjective }),
      ...(previousAdjective && { descriptionPrevious: previousAdjective }),
      ...(color && { separatorColor: color }),
      id: (page as Page).ownerId as string,
    });
  }

  function discardAllChanges() {
    introSectionForm.resetFields();
    footerSectionForm.resetFields();
    setPageRecipientsEditData([]);

    setAllSectionStatusToViewMode();
  }

  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);

  if (isGetPageLoading) {
    return <LoadingPage />;
  }

  if (!page) {
    return <PageNotFound />;
  }

  const navbar = isViewingPublicly ? null : (
    <Navbar
      isEditing={isOneOfSectionsEditing}
      isSavingAll={
        isUpdatingPage || isUpdatingPageRecipietns || isUploadingPageFooterImage
      }
      page={page}
      sectionsStatus={sectionsStatus}
      onCloseAllEdit={setAllSectionStatusToViewMode}
      onDiscardAllChanges={discardAllChanges}
      onSaveAllChanges={saveAllChanges}
    />
  );

  return (
    <>
      <Title title="Gallery - Giving Side" />
      {screens.lg && (
        <S.PrivacyStatusContainer>
          <Affix offsetTop={24} target={() => container}>
            <PrivacyStatusInfo
              isViewingPublicly={isViewingPublicly}
              page={page}
            />
          </Affix>
        </S.PrivacyStatusContainer>
      )}
      <S.Container ref={setContainer}>
        <S.DonorGalleryGlobalStyle />
        {navbar}
        <MobileDismissableAlert />

        <IntroSection
          introSectionForm={introSectionForm}
          isSaving={isUpdatingPage}
          isViewingPublicly={isViewingPublicly}
          page={page}
          sectionStatus={getSectionStatus(SECTION_NAMES.INTRO_SECTION)}
          setSectionStatus={createSectionStatusSetter(
            SECTION_NAMES.INTRO_SECTION
          )}
          onSave={saveIntroSection}
        />
        <CardsSection
          isLoading={isPageRecipientIdle || isPageRecipientsLoading}
          isSaveButtonLoading={isUpdatingPageRecipietns}
          isViewingPublicly={isViewingPublicly}
          page={page}
          pageRecipientsEditData={pageRecipientsEditData}
          savedPageRecipients={pageRecipients}
          sectionStatus={getSectionStatus(SECTION_NAMES.CARDS_SECTION)}
          setPageRecipientsEditData={setPageRecipientsEditData}
          setSectionStatus={createSectionStatusSetter(
            SECTION_NAMES.CARDS_SECTION
          )}
          onSave={saveCardsSectionChanges}
        />
        <AdjectiveSection
          donorId={page?.ownerId as string}
          isUpdating={isUpdatingDonor}
          isViewingPublicly={isViewingPublicly}
          sectionStatus={getSectionStatus(SECTION_NAMES.ADJECTIVE_SECTION)}
          setSectionStatus={createSectionStatusSetter(
            SECTION_NAMES.ADJECTIVE_SECTION
          )}
          onSave={saveAdjectiveSection}
        />
        <S.FooterBar>
          <Text type="body1reg2">© Giving Side {getCurrentYear()}</Text>
        </S.FooterBar>
      </S.Container>
      {!screens.lg && (
        <S.PrivacyStatusContainer>
          <Affix offsetBottom={24}>
            <PrivacyStatusInfo
              isViewingPublicly={isViewingPublicly}
              page={page}
            />
          </Affix>
        </S.PrivacyStatusContainer>
      )}
    </>
  );
}

export default DonorGallery;
