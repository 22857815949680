import { notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dateFormat from 'dateformat';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { downloadGivesExcel } from 'src/client/api/GiveApi';
import { getRecipientWebsite } from 'src/client/api/RecipientApi';
import {
  Box,
  Flex,
  ReceiptModal,
  TaxTable,
  Title,
} from 'src/client/components';
import EditGiveModal from 'src/client/components/EditGiveModal';
import Text from 'src/client/components/Text';
import { ActionTableCell, RecipientTableCell } from 'src/client/components/v2';

import { DASHBOARD_VIEW } from 'src/client/constants/DonorDashboard';
import { updateGivesQueryData, useGetGives } from 'src/client/hooks/queries';
import useGetQueryString from 'src/client/hooks/useGetQueryString';
import { analytics } from 'src/client/libs/segment';
import { MONTH_DAY_MMM_D } from 'src/commons/constants/dateFormat';
import { SEGMENT_EVENTS } from 'src/commons/constants/segment';
import { taxDeductibles } from 'src/commons/constants/tax';
import { Donor, Give, TAX_DEDUCTIBLE_STATUS } from 'src/commons/types';
import { TAX_DEDUCTIBLE } from 'src/commons/types/Give.type';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

import TaxDeductionCover from './components/Cover';
import Resources from './components/Resources';
import Statistics from './components/Statistics';
import StatusKeys from './components/StatusKeys';
import StatusKeysModal from './components/StatusKeysModal';
import TaxDeductibleCell from './components/TaxDeductibleCell';

import * as S from './styles';

const { ALL_TIME } = DASHBOARD_VIEW;

type Props = {
  donor: Donor;
};

export default function DonorTax(props: Props) {
  const { donor } = props;

  const { taxYear } = useGetQueryString();
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  const [receiptModalGiveId, setReceiptModalGiveId] = useState('');
  const [selectedEditGive, setSelectedEditGive] = useState<Give>();
  const [isStatusKeysModalVisible, setIsStatusKeysModalVisible] =
    useState(false);
  const queryClient = useQueryClient();

  const hasSelectedGive = !isEmpty(selectedEditGive);
  const selectedTaxYear =
    taxYear === ALL_TIME ? undefined : taxYear?.toString();

  const { data: givesResponse, isFetching: isGetGivesLoading } = useGetGives(
    {
      donorId: donor.id,
      giveYear: selectedTaxYear,
    },
    { enabled: !!donor.id && !!taxYear }
  );

  function handleTaxDeductibleGivesExport() {
    downloadGivesExcel({
      donorId: donor.id,
      giveYear: selectedTaxYear,
      taxDeductibleStatus: TAX_DEDUCTIBLE_STATUS.TAX_DEDUCTIBLE,
    });
  }

  function handleUnknownTaxDeductibleGives() {
    downloadGivesExcel({
      donorId: donor.id,
      giveYear: selectedTaxYear,
      taxDeductibleStatus: TAX_DEDUCTIBLE_STATUS.UNKNOWN,
    });
  }

  function showReceiptModal(giveId: string) {
    setIsReceiptModalVisible(true);
    setReceiptModalGiveId(giveId);

    analytics.track(SEGMENT_EVENTS.USER_CLICKED_RECEIPT_ICON, {
      giveId,
    });
  }

  function showStatusKeyModal() {
    setIsStatusKeysModalVisible(true);
  }

  function closeStatusKeyModal() {
    setIsStatusKeysModalVisible(false);
  }

  function closeReceiptModal() {
    setIsReceiptModalVisible(false);
  }

  function handleEditClick(give: Give) {
    setSelectedEditGive(give);
    analytics.track(SEGMENT_EVENTS.USER_OPENED_EDIT_GIVE_MODAL, {
      giveId: give.id,
    });
  }

  async function closeEditModal() {
    if (selectedEditGive) {
      analytics.track(SEGMENT_EVENTS.USER_CLOSED_EDIT_GIVE_MODAL, {
        giveId: selectedEditGive.id,
      });
    }

    setSelectedEditGive(undefined);
  }

  function handleSaveGive(newGive: Give) {
    updateGivesQueryData(queryClient, newGive, {
      donorId: donor.id,
      giveYear: selectedTaxYear,
    });

    closeEditModal();
    notification.success({ message: 'Give updated successfully' });
  }

  async function handleLinkIconClick(recipientId: string, giveId: string) {
    try {
      const { website } = await getRecipientWebsite(recipientId);

      window.open(`https://${website}`, '_blank');
    } catch (e) {
      showReceiptModal(giveId);
    }

    analytics.track(SEGMENT_EVENTS.USER_CLICKED_LINK_ICON, {
      recipientId,
      giveId,
    });
  }

  const unknownTaxDeductiblesGives = givesResponse?.data?.filter(
    (give) => give.taxDeductible === TAX_DEDUCTIBLE.UNKNOWN
  );

  const taxDeductiblesGives = givesResponse?.data?.filter((give) =>
    taxDeductibles.includes(give.taxDeductible as TAX_DEDUCTIBLE)
  );

  const editGiveModal = hasSelectedGive && (
    <EditGiveModal
      centered
      give={selectedEditGive}
      open={hasSelectedGive}
      title="Edit Give"
      width={866}
      onCancel={closeEditModal}
      onClose={closeEditModal}
      onSave={handleSaveGive}
    />
  );

  const columns = makeColumn({
    onReceiptIconClick: showReceiptModal,
    onWebsiteLinkClick: handleLinkIconClick,
    onDeductibleStatusKeysClick: showStatusKeyModal,
    onEditClick: handleEditClick,
  });

  return (
    <>
      <Title title="Tax Info - Giving Side" />
      <S.Container>
        <S.Content>
          <TaxDeductionCover donorId={donor.id} />
          <Statistics
            gives={givesResponse?.data || []}
            isLoading={isGetGivesLoading}
          />
          <Box margin="0 0 40px 0">
            <TaxTable
              columns={columns}
              dataSource={taxDeductiblesGives || []}
              id="tax-deductible-gives-table"
              isLoading={isGetGivesLoading}
              title="Tax-deductible gives"
              onExportExcelClick={handleTaxDeductibleGivesExport}
            />
          </Box>
          <S.UnknownTaxDeductibleTableContainer>
            <TaxTable
              hasLeftBorder
              columns={columns}
              dataSource={unknownTaxDeductiblesGives || []}
              id="unknown-tax-deductible-gives-table"
              isLoading={isGetGivesLoading}
              subtitle="The tax status of the following gives were not clear from the receipt or email. Please update the status of the gives below. "
              title="Tax deductibility status unknown"
              onExportExcelClick={handleUnknownTaxDeductibleGives}
            />
            <ReceiptModal
              giveId={receiptModalGiveId}
              isVisible={isReceiptModalVisible}
              onCancel={closeReceiptModal}
            />
            <StatusKeysModal
              isVisible={isStatusKeysModalVisible}
              onClose={closeStatusKeyModal}
            />
          </S.UnknownTaxDeductibleTableContainer>
          <Resources />
          <StatusKeys />
          {editGiveModal}
        </S.Content>
      </S.Container>
    </>
  );
}

type MakeColumnParams = {
  onReceiptIconClick: (giveId: string) => void;
  onWebsiteLinkClick: (recipientId: string, giveId: string) => void;
  onEditClick: (give: Give) => void;
  onDeductibleStatusKeysClick: () => void;
};

function makeColumn(params: MakeColumnParams): ColumnsType<Give> {
  const {
    onReceiptIconClick,
    onWebsiteLinkClick,
    onEditClick,
    onDeductibleStatusKeysClick,
  } = params;

  return [
    {
      title: 'Date',
      dataIndex: 'giveDate',
      key: 'giveDate',
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.giveDate);
        const dateB = new Date(b.giveDate);

        return dateA.getTime() - dateB.getTime();
      },
      defaultSortOrder: 'descend',
      render: (date: string) => (
        <Text type="body1reg2">{dateFormat(date, MONTH_DAY_MMM_D)}</Text>
      ),
    },
    {
      title: 'Recipient',
      dataIndex: 'recipientName',
      key: 'recipientName',
      render: (text: string, give: Give) => <RecipientTableCell give={give} />,
      sorter: (a: any, b: any) => (a.recipientName > b.recipientName ? 1 : -1),
    },
    {
      title: 'Contributed',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => (
        <Text type="body1reg2">{formatToCurrency(amount)}</Text>
      ),
      sorter: (a: any, b: any) => a.amount - b.amount,
      align: 'right',
    },
    {
      title: () => (
        <Flex alignItems="center" gap="3px" justifyContent="space-between">
          <span>Tax-Deductible</span>
          <S.StyledInfoCircleOutlined
            data-cy="tax-deductible-status-key-info"
            onClick={onDeductibleStatusKeysClick}
          />
        </Flex>
      ),
      dataIndex: 'taxDeductible',
      key: 'taxDeductible',
      width: 170,
      render: (taxDeductible: string, tableData: Give) => (
        <TaxDeductibleCell giveData={tableData} taxDeductibe={taxDeductible} />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 160,
      render: (text: string, give: Give) => {
        function handleClickEdit() {
          onEditClick(give);
        }

        function handleClickReceipt() {
          onReceiptIconClick(give.id);
        }

        function handleClickWebsiteLink() {
          if (give.recipientId) {
            onWebsiteLinkClick(give.recipientId, give.id);
          }
        }

        return (
          <ActionTableCell
            onClickEdit={handleClickEdit}
            onClickReceipt={handleClickReceipt}
            onClickWebsiteLink={handleClickWebsiteLink}
          />
        );
      },
    },
  ];
}
