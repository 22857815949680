import { FormInstance, Modal } from 'antd';
import React, { useState } from 'react';

import { Box, Button, Flex, Text } from 'src/client/components';

import {
  DEFAULT_GALLERY_SHAPE,
  GALLERY_COLORS,
  GalleryShape,
  GridOptionType,
} from 'src/commons/constants/gallery';

import { Page } from 'src/commons/types';

import GridOptionPicker from '../GridOptionPicker';

import * as S from './styles';

type Props = React.ComponentProps<typeof Modal> & {
  introSectionForm: FormInstance<any>;
  onSave?: (value: Page['galleryShape']) => void;
};

function GalleryShapeModal(props: Props) {
  const { introSectionForm, onSave, ...modalProps } = props;

  const galleryShape = introSectionForm.getFieldValue('galleryShape');

  const [selectedColor, setSelectedColor] = useState(
    galleryShape?.color ?? DEFAULT_GALLERY_SHAPE?.color
  );
  const [selectedShape, setSelectedShape] = useState<GalleryShape>(
    galleryShape?.name ?? DEFAULT_GALLERY_SHAPE?.name
  );

  function handleSave() {
    if (onSave) {
      onSave({
        name: selectedShape,
        color: selectedColor,
      });
    }
  }

  function handleColorChange(value: string) {
    setSelectedColor(value);
  }

  function handleShapeChange(value: string) {
    setSelectedShape(value as GalleryShape);
  }

  const isSaveDisabled =
    galleryShape.name === selectedShape && galleryShape.color === selectedColor;

  return (
    <S.StyledModal data-cy="gallery-shape-modal" footer={false} {...modalProps}>
      <Text type="h4med2">Customize Shape</Text>
      <Text type="body2reg2">
        Select color and shape to display on your gallery.
      </Text>
      <Box margin="24px 0 0 0" />
      <Text type="label1med2">Color</Text>
      <GridOptionPicker
        colors={GALLERY_COLORS}
        selectedColor={selectedColor}
        type={GridOptionType.COLOR}
        onChange={handleColorChange}
      />
      <Box margin="24px 0 0 0" />
      <Text type="label1med2">Shape</Text>
      <GridOptionPicker
        selectedColor={selectedColor}
        selectedShape={selectedShape}
        type={GridOptionType.SHAPE}
        onChange={handleShapeChange}
      />
      <Flex gap="8px" justifyContent="flex-end" margin="32px 0 0 0">
        <Button
          data-cy="gallery-shape-modal-cancel-btn"
          type="secondary"
          onClick={modalProps?.onCancel}
        >
          Cancel
        </Button>
        <Button
          data-cy="gallery-shape-modal-save-btn"
          disabled={isSaveDisabled}
          type="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </Flex>
    </S.StyledModal>
  );
}

export default GalleryShapeModal;
