import {
  FileTextOutlined,
  InfoCircleOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';

import styled from 'styled-components';

import Button from 'src/client/components/Button';

export const Content = styled.div`
  margin: auto;
  padding: 40px 16px 10px 16px;
  width: 100%;
  max-width: 1440px;

  @media ${({ theme }) => theme.device.mobileL} {
    padding: 70px 80px;
  }
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.darkSurface1};
  color: ${(props) => props.theme.colors.white};
`;

export const StyledPaperClipIcon = styled(PaperClipOutlined)`
  color: ${(props) => props.theme.colors.teal2};
`;

export const StyledFileTextIcon = styled(FileTextOutlined)`
  font-size: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.gray2};
`;

export const RecipientNameButton = styled(Button)`
  padding: 0;
`;

export const CardsContainer = styled.div`
  margin: 24px auto 70px auto;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-direction: column;

  @media ${(props) => props.theme.device.laptop} {
    flex-direction: row;
    gap: 31px;
  }
`;

export const UnknownTaxDeductibleTableContainer = styled.div`
  margin-bottom: 40px;

  @media ${(props) => props.theme.device.laptop} {
    margin-bottom: 125px;
  }
`;

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${(props) => props.theme.colors.lightEmphasis2};
  cursor: pointer;
  line-height: 1.25rem;

  svg {
    height: 18px;
    width: 18px;
  }
`;
