import { notification } from 'antd';
import { SelectValue } from 'antd/lib/select';
import capitalize from 'lodash/capitalize';
import React, { useContext } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import Select from 'src/client/components/v2/Select';
import { AccountContext } from 'src/client/contexts/AccountContext';
import { CurrentDonorInViewContext } from 'src/client/contexts/CurrentDonorInViewContext';
import { useUpdateGiveMutation } from 'src/client/hooks/mutations';
import { updateGivesQueryData } from 'src/client/hooks/queries';
import useGetQueryString from 'src/client/hooks/useGetQueryString';
import { analytics } from 'src/client/libs/segment';
import { SEGMENT_EVENTS } from 'src/commons/constants/segment';
import { Give, TAX_DEDUCTIBLE } from 'src/commons/types';
import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

type Props = {
  taxDeductibe: string;
  giveData: Give;
};

const { Option } = Select;

function TaxDeductibleCell(props: Props) {
  const { giveData, taxDeductibe } = props;

  const queryString = useGetQueryString();
  const queryClient = useQueryClient();
  const account = useContext(AccountContext);
  const currentDonorInView = useContext(CurrentDonorInViewContext);

  const { mutateAsync: updateGive, isLoading } = useUpdateGiveMutation({
    onSuccess: (updatedGive) => {
      const newStatus = updatedGive.data.taxDeductible as string;

      notification.success({
        message: `Give tax deductible updated to “${capitalize(newStatus)}”`,
      });

      updateGivesQueryData(queryClient, updatedGive.data, {
        donorId: getDonorId(),
        giveYear: getSelectedTaxYear(),
      });
    },
  });

  function getDonorId() {
    if (account?.isRoleAdmin && currentDonorInView) {
      return currentDonorInView?.id;
    }

    if (account?.isRoleDonor) {
      return account.id;
    }
  }

  function getSelectedTaxYear() {
    return (
      queryString.taxYear?.toLocaleString() ||
      new Date().getFullYear().toString()
    );
  }

  async function handleChange(value: SelectValue) {
    await updateGive({
      id: giveData.id,
      taxDeductible: value as TAX_DEDUCTIBLE,
    });

    analytics.track(SEGMENT_EVENTS.USER_UPDATED_TAX_DEDUCTIBLE_STATUS, {
      previousTaxDeductibleStatus: taxDeductibe,
      newTaxDeductibleStatus: value,
      giveId: giveData.id,
      amount: giveData.amount,
      recipientName: giveData.recipientName,
      recipientId: giveData.recipientId,
      platformName: giveData.platformName,
      platformId: giveData.platformId,
    });
  }

  const options = Object.entries(TAX_DEDUCTIBLE).map(([key, value]) => (
    <StyledOption key={value} value={value}>
      {capitalizeFirstLetter(value)}
    </StyledOption>
  ));

  return (
    <StyledSelect
      bordered={false}
      data-cy="tax-deductible-status-select-menu"
      disabled={isLoading}
      loading={isLoading}
      value={taxDeductibe}
      onChange={handleChange as any}
    >
      {options}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  width: 100%;
  text-transform: capitalize;
  border-radius: 50px;
  background-color: ${(props) => props.theme.colors.darkSurface3};
  padding: 2px 10px;

  &&&& .ant-select-selector {
    color: ${(props) => props.theme.colors.white};
  }

  &&&& .anticon-loading {
    color: ${(props) => props.theme.colors.white};

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .anticon-down {
    color: ${(props) => props.theme.colors.white};
  }
`;

const StyledOption = styled(Option)`
  text-transform: capitalize;
`;

export default TaxDeductibleCell;
