import {
  DndContext,
  DragEndEvent,
  SensorDescriptor,
  SensorOptions,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { forwardRef } from 'react';

import { LoadingCard } from 'src/client/components';
import { PageRecipient } from 'src/commons/types';

import GiveCard from '../GiveCard';

import * as S from './styles';

type Props = {
  isLoading: boolean;
  hiddenPageRecipients: PageRecipient[] | undefined;
  onFeaturePageRecipient: (pageRecipientId: string) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
};

const HiddenCardsEditMode = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    isLoading,
    hiddenPageRecipients,
    onFeaturePageRecipient,
    sensors,
    handleDragEnd,
    handleDragStart,
  } = props;

  const screens = useBreakpoint();

  const hiddenCards = hiddenPageRecipients?.map((pageRecipient) => (
    <GiveCard
      isEditMode
      editModeConfig={{
        primaryButtonText: 'Show',
        isSecondaryButtonHidden: true,
        primaryButtonProps: {
          onClick: () => onFeaturePageRecipient(pageRecipient.id),
        },
      }}
      isDraggable={false}
      key={pageRecipient.id}
      pageRecipient={pageRecipient}
    />
  ));

  return (
    <DndContext
      modifiers={[restrictToParentElement]}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <S.StyledDragAndDropArea
        data-cy="hidden-cards-drag-and-drop-area"
        emptyStateInstructionText={
          <div>
            To prevent people from seeing specific recipients,{' '}
            {screens.lg ? 'hover' : 'tap'} over them and click <span>Hide</span>
          </div>
        }
        instructionTabText="Recipients in this section will not be visible when gallery is published to the public"
        isEmpty={!hiddenPageRecipients?.length}
        ref={ref}
      >
        <LoadingCard isLoading={isLoading}>{hiddenCards}</LoadingCard>
      </S.StyledDragAndDropArea>
    </DndContext>
  );
});

export default HiddenCardsEditMode;
