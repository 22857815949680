import React from 'react';

import { LoadingCard } from 'src/client/components';
import { PageRecipient } from 'src/commons/types';

import FeaturedCard from '../FeaturedCard';

import * as S from './styles';

type Props = {
  featuredPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
};

function FeaturedCardsViewMode(props: Props) {
  const { featuredPageRecipients, isLoading } = props;

  const featuredCards = featuredPageRecipients?.map((pageRecipient) => (
    <FeaturedCard
      data-page-recipient-id={pageRecipient.id}
      data-page-recipient-visibility="featured-card"
      key={pageRecipient.id}
      pageRecipient={pageRecipient}
      onClick={pageRecipient.recipientWebsite ? () => window.open('https://' + pageRecipient.recipientWebsite!, "_blank") : undefined}
    />
  ));

  return (
    <S.Container>
      <LoadingCard
        isLoading={isLoading}
        spinProps={{
          'data-cy': 'featured-cards-spinner',
        }}
      >
        {featuredCards}
      </LoadingCard>
    </S.Container>
  );
}

export default FeaturedCardsViewMode;
