import React from 'react';

import * as S from './styles';

type Props = {
  onRandomizeQuote: () => void;
  onSeeAllQuotes: () => void;
};

function QuotesButtonGroup(props: Props) {
  const { onRandomizeQuote, onSeeAllQuotes } = props;

  return (
    <S.Container>
      <S.StyledButton
        isRandomize
        data-cy="randomize-quote-btn"
        onClick={onRandomizeQuote}
      >
        <S.TextContainer>
          <S.StyledText>
            Surprise me with a <br />
            new quote
          </S.StyledText>
          <S.StyledSubtitle>See how it looks above</S.StyledSubtitle>
        </S.TextContainer>
        <S.StyledIcon src="/quotesRandomizeIcon.png" />
      </S.StyledButton>
      <S.StyledButton data-cy="see-all-quotes-btn" onClick={onSeeAllQuotes}>
        <S.TextContainer>
          <S.StyledText>
            Browse more
            <br /> quotes
          </S.StyledText>
          <S.StyledSubtitle>
            View complete list of curated quotes
          </S.StyledSubtitle>
        </S.TextContainer>
        <S.StyledIcon src="/quotesListIcon.png" />
      </S.StyledButton>
    </S.Container>
  );
}

export default QuotesButtonGroup;
