import { Table } from 'antd';

import styled from 'styled-components';

import {
  BODY_2_REGULAR,
  BUTTON_2_2,
  BODY_1_MED_2,
  BODY_1_REG_2,
} from 'src/client/constants/text';

export const StyledTable = styled(Table)`
  tbody .ant-table-cell {
    ${BODY_1_REG_2};
    color: ${(props) => props.theme.colors.gray10};
  }

  thead .ant-table-cell {
    color: ${(props) => props.theme.colors.darkHighEmphasis};
  }

  .ant-pagination-simple-pager {
    display: none;
  }

  .ant-table-column-title,
  th {
    ${BODY_1_MED_2};
  }

  td {
    ${BODY_1_REG_2};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #f0f0f0;
  }

  tbody tr:nth-child(even) {
    background-color: #fafafa;
  }

  td .anticon svg {
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }
`;

export const StyledTableV2 = styled(Table)`
  background-color: ${(props) => props.theme.colors.darkSurface2};
  border-radius: 8px;

  width: 100%;
  overflow: auto;

  .ant-pagination-simple-pager {
    display: none;
  }

  .ant-table-thead > tr > th,
  .ant-table {
    background-color: ${(props) => props.theme.colors.darkSurface3};
  }

  .ant-table-thead > tr > th {
    padding: 17px 16px;
    color: ${(props) => props.theme.colors.white};
    border-bottom: 0;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    vertical-align: baseline;
  }

  .ant-table-footer,
  .ant-table-summary {
    background-color: ${(props) => props.theme.colors.darkSurface3};
    border-top: 1px solid ${(props) => props.theme.colors.lightSurface20};
    color: ${(props) => props.theme.colors.white};
  }

  .ant-table-summary > tr > td {
    border-bottom: 0;
  }

  .ant-table-tbody > tr.ant-table-placeholder > td > .ant-empty-normal {
    color: ${(props) => props.theme.colors.white};

    ${BODY_2_REGULAR}
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: ${(props) => props.theme.colors.darkSurface2};
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-radius: 0;
  }

  .ant-table-tbody,
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${(props) => props.theme.colors.darkSurface2};
  }

  .ant-table-cell {
    color: ${(props) => props.theme.colors.white};
  }

  .ant-table-column-sort {
    background: ${(props) => props.theme.colors.darkSurface2};
  }

  .ant-pagination {
    display: flex;
    justify-content: center;
  }

  .ant-pagination-simple > * {
    height: auto;
  }

  &&& .ant-pagination-simple button {
    padding: 10px 8px 10px 10px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border-radius: 2px;
    border: none;
    vertical-align: middle;
    ${BUTTON_2_2};
  }

  &&&& .ant-pagination-simple button[disabled] {
    padding: 10px 8px 10px 10px;
    background: ${(props) => props.theme.colors.surfaceDisabled} !important;
    color: ${(props) => props.theme.colors.darkEmphasis3};
    border-radius: 2px;
    border: none;
  }
`;
