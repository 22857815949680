import styled, { css } from 'styled-components';

type ContainerProps = {
  isBigScreen: boolean;
};

export const Container = styled.div<ContainerProps>`
  .slick-slide.slick-active.slick-center {
    ${(props) =>
      props.isBigScreen &&
      css`
        position: relative;
        transform: scale(1.35);
        z-index: 2;
      `}
  }

  .slick-slide.slick-active.slick-center > div > div > div {
    box-shadow: ${(props) => props.theme.shadows.shadow3};
  }
  .slick-slide.slick-active.slick-center p {
    display: block;
  }
`;

export const ArrowButton = styled.button`
  display: flex;
  height: 50px;
  width: 50px;
  color: ${(props) => props.theme.colors.white};
  opacity: 0.5;

  &:hover,
  &:focus,
  &:focus:active {
    opacity: 1;
    color: ${(props) => props.theme.colors.white};
  }

  &.slick-prev {
    left: -50px;

    @media ${(props) => props.theme.device.tablet} {
      left: -70px;
    }
  }

  &.slick-next {
    right: -50px;

    @media ${(props) => props.theme.device.tablet} {
      right: -70px;
    }
  }

  svg {
    font-size: 30px;
  }

  &:before {
    content: none;
  }
`;
