import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { AccountContext } from '../contexts/AccountContext';
import { useGetDonorById, useGetPage } from '../hooks/queries';

import DonorGallery from './DonorGallery';
import LoadingPage from './LoadingPage';
import PageNotFound from './PageNotFound';

type UrlParams = {
  pageId: string;
};

function PublicGallery() {
  const match = useRouteMatch<UrlParams>();
  const account = useContext(AccountContext);

  const { data: page, isLoading: isPageLoading } = useGetPage(
    match.params.pageId
  );

  const { data: pageOwner, isLoading: isDonorLoading } = useGetDonorById(
    page?.ownerId
  );

  if (isPageLoading || isDonorLoading) {
    return <LoadingPage />;
  }

  if (!pageOwner) {
    return <PageNotFound />;
  }

  const isPageOwnedByCurrentUser = account?.id === pageOwner?.id;

  const shouldShowGallery =
    !!account?.isRoleAdmin || isPageOwnedByCurrentUser || page?.isVisible;

  if (!shouldShowGallery) {
    return <PageNotFound />;
  }

  return <DonorGallery isViewingPublicly />;
}

export default PublicGallery;
