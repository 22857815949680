import { Form } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';

import LoadingAnimation from 'src/client/components/LoadingAnimation';
import { Page } from 'src/commons/types';

import QuotesButtonGroup from '../QuotesButtonGroup';

import * as S from './styles';

type Props = {
  isSetQuoteLoading: boolean;
  isEditing: boolean;
  isViewingPublicly: boolean;
  page: Page;
  handleRandomizeQuote: () => void;
  handleSeeAllQuotes: () => void;
};

function QuoteTextArea(props: Props) {
  const {
    isEditing,
    isSetQuoteLoading,
    isViewingPublicly,
    page,
    handleRandomizeQuote,
    handleSeeAllQuotes,
  } = props;

  const screens = useBreakpoint();

  const isQuoteEmptyAndViewingPublicly = !page.quote && isViewingPublicly;
  const isAuthorVisible = isViewingPublicly
    ? page.quote && page.author
    : page.author || isEditing; // author field should always be hidden when empty in preview mode, even when viewing privately

  const fieldNameLabel = isEditing && (
    <S.StyledEditLabel>Add a quote that inspires you</S.StyledEditLabel>
  );

  const quoteTextAreaRowsMax = 3;

  return (
    <>
      {fieldNameLabel}
      <S.InputContainer isEditing={isEditing}>
        {!isSetQuoteLoading ? (
          <>
            {!isQuoteEmptyAndViewingPublicly && (
              <Form.Item name="quote">
                <S.QuoteTextArea
                  autoSize={{
                    minRows: screens.xxl ? quoteTextAreaRowsMax : 2,
                  }}
                  data-cy="quote-text-area"
                  isEditing={isEditing}
                  key={`${String(isEditing)}-quote`}
                  name="quote"
                  placeholder={`Add a quote that inspires you...`}
                />
              </Form.Item>
            )}
            {isAuthorVisible && (
              <S.AuthorContainer>
                <S.AuthorHyphen isEditing={isEditing} isEmpty={!page.author}>
                  -
                </S.AuthorHyphen>
                <Form.Item name="author">
                  <S.AuthorTextArea
                    autoSize={{ maxRows: 2 }}
                    data-cy="author-text-area"
                    isEditing={isEditing}
                    key={`${String(isEditing)}-author`}
                    name="author"
                    placeholder={'Author'}
                  />
                </Form.Item>
              </S.AuthorContainer>
            )}
          </>
        ) : (
          <S.StyledSpinnerContainer>
            <LoadingAnimation size="large" />
          </S.StyledSpinnerContainer>
        )}

        {isEditing && (
          <QuotesButtonGroup
            onRandomizeQuote={handleRandomizeQuote}
            onSeeAllQuotes={handleSeeAllQuotes}
          />
        )}
      </S.InputContainer>
    </>
  );
}

export default QuoteTextArea;
